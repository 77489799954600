import React, { useMemo, useState } from 'react';
import {
  Form, FormGroup, FormField, Button, Radio, Message,
} from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import _ from 'lodash';
import { contactUrl, contactEditProfileActionUrl } from '../../lib/requests/routes.js.erb';
import useSaveAction from '../../lib/hooks/useSaveAction';
import I18n from '../../lib/i18n';
import UserData from './UserData';
import Loading from '../Requests/Loading';
import { useDispatch, useStore } from '../../lib/providers/StoreProvider';
import { clearForm, updateContactRecipient } from './store/actions';
import Wct from './SubForms/Wct';
import Wrt from './SubForms/Wrt';
import Wst from './SubForms/Wst';
import Competition from './SubForms/Competition';
import Errored from '../Requests/Errored';

const CONTACT_RECIPIENTS = [
  'competition',
  'wct',
  'wrt',
  'wst',
];

const CONTACT_RECIPIENTS_MAP = _.keyBy(CONTACT_RECIPIENTS, _.camelCase);

const getFormRedirection = (formValues) => {
  if (formValues.contactRecipient === CONTACT_RECIPIENTS_MAP.wrt) {
    if (formValues[CONTACT_RECIPIENTS_MAP.wrt].queryType === 'edit_profile') {
      return contactEditProfileActionUrl;
    }
  }
  return null;
}

export default function ContactForm({
  loggedInUserData,
  recaptchaPublicKey,
}) {
  const { save, saving } = useSaveAction();
  const [captchaValue, setCaptchaValue] = useState();
  const [captchaError, setCaptchaError] = useState(false);
  const [saveError, setSaveError] = useState();
  const [contactSuccess, setContactSuccess] = useState(false);
  const contactFormState = useStore();
  const dispatch = useDispatch();
  const { formValues: { contactRecipient: selectedContactRecipient, userData } } = contactFormState;
  const formRedirection = useMemo(() => getFormRedirection(contactFormState.formValues));

  const isFormValid = (
    selectedContactRecipient && userData.name && userData.email && (captchaValue || formRedirection)
  );

  const contactSuccessHandler = () => {
    dispatch(clearForm({
      userName: loggedInUserData?.user?.name,
      userEmail: loggedInUserData?.user?.email,
    }));
    setContactSuccess(true);
  };

  const recipientChangeHandler = (__, { value }) => {
    setContactSuccess(false);
    dispatch(updateContactRecipient(value));
  };

  const SubForm = useMemo(() => {
    if (!selectedContactRecipient) return null;
    switch (selectedContactRecipient) {
      case CONTACT_RECIPIENTS_MAP.competition:
        return Competition;
      case CONTACT_RECIPIENTS_MAP.wct:
        return Wct;
      case CONTACT_RECIPIENTS_MAP.wrt:
        return Wrt;
      case CONTACT_RECIPIENTS_MAP.wst:
        return Wst;
      default:
        return null;
    }
  }, [selectedContactRecipient]);

  if (saving) return <Loading />;
  if (saveError) return <Errored error={saveError} />;
  return (
    <>
      { contactFormState.formValues?.competition?.message && (
      <Message
        warning
        content={I18n.t('page.contacts.prefilled')}
      />
      )}
      {contactSuccess && (
        <Message
          success
          content={I18n.t('page.contacts.success_message')}
        />
      )}
      <Form
        onSubmit={() => {
          if (isFormValid) {
            const formData = new FormData();
            formData.append('formValues', JSON.stringify(contactFormState.formValues));
            formData.append('attachment', contactFormState.attachments[0]);
            save(
              contactUrl,
              formData,
              contactSuccessHandler,
              { method: 'POST', headers: {}, body: formData },
              setSaveError,
            );
          }
        }}
        error={!!captchaError}
      >
        <UserData loggedInUserData={loggedInUserData} />
        <FormGroup grouped>
          <div>{I18n.t('page.contacts.form.contact_recipient.label')}</div>
          {CONTACT_RECIPIENTS.map((contactRecipient) => (
            <FormField key={contactRecipient}>
              <Radio
                label={I18n.t(`page.contacts.form.contact_recipient.${contactRecipient}.label`)}
                name="contactRecipient"
                value={contactRecipient}
                checked={selectedContactRecipient === contactRecipient}
                onChange={recipientChangeHandler}
              />
            </FormField>
          ))}
        </FormGroup>
        {SubForm && <SubForm />}
        {formRedirection ? (
          <Button
            disabled={!isFormValid}
            href={formRedirection}
          >
            {I18n.t('page.contacts.form.next_button')}
          </Button>
        ) : (
          <>
            <FormField>
              <ReCAPTCHA
                sitekey={recaptchaPublicKey}
                // onChange is a mandatory parameter for ReCAPTCHA. According to the documentation,
                // this is called when user successfully completes the captcha, hence we are
                // assuming that any existing errors will be cleared when onChange is called.
                onChange={setCaptchaValue}
                onErrored={setCaptchaError}
              />
              {captchaError && (
                <Message
                  error
                  content={I18n.t('page.contacts.form.captcha.validation_error')}
                />
              )}
            </FormField>
            <Button
              disabled={!isFormValid}
              type="submit"
            >
              {I18n.t('page.contacts.form.submit_button')}
            </Button>
          </>
        )}
      </Form>
    </>
  );
}
